<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getSaleInfoPage"
      :deleteFun="deleteSaleInfo"
      :createFun="createSaleInfo"
      :updateFun="updateSaleInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { validateMoney } from '@/utils/validate'
import { getSaleOptions, getFeeTypes, getSaleInfoPage, deleteSaleInfo, createSaleInfo, updateSaleInfo } from '@/api/wms/sale'
import { getCustomer } from '@/api/common'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '购买单位', name: 'buyer_name', fieldType: 'select', queryType: '', options: []
        },
        {
          label: '货物名称', name: 'good_name', fieldType: 'select', queryType: '', options: []
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 80,
          align: 'centr'
        },
        {
          title: '出货单价',
          dataIndex: 'price'
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name'
        },
        {
          title: '货物名称',
          dataIndex: 'good_name'
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        price: [
          { required: true, message: '请填写出货单价', trigger: 'blur' },
          { validator: this.checkAmount }
        ]
      },
      modelTitle: '固定费用',
      formFields: [
        {
          label: '出货单价', name: 'price', type: 'default'
        },
        {
          label: '购买单位', name: 'buyer', type: 'select', options: []
        },
        {
          label: '货物名称', name: 'good_id', type: 'select', options: []
        },
        {
          label: '费用名称', name: 'fee_id', type: 'select', options: [], func: getFeeTypes
        }
      ],
      moduleName: 'sale_fix_fee'
    }
  },
  created() {
    // getSaleOptions('wms_buyer').then(v => {
    //   this.queryFields[0]['options'] = v
    //   this.formFields[1]['options'] = v
    // })
    getCustomer({ department: 7, customerName: '' }).then(res => {
      this.queryFields[0]['options'] = res
      this.formFields[1]['options'] = res
    })
    getSaleOptions('good_info').then(v => {
      this.queryFields[1]['options'] = v
      this.formFields[2]['options'] = v
    })
  },
  methods: {
    getSaleInfoPage,
    createSaleInfo,
    updateSaleInfo,
    deleteSaleInfo,
    checkAmount(rule, value, callback) {
      if (validateMoney(value)) {
        callback()
      } else {
        callback(new Error('请输入合法的金额'))
      }
    }
  }
}
</script>
